import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from '../../App' 
import { Select, Avatar, Menu, Form, Input, Button } from 'antd'
import firebase, { analytics } from 'firebase'
import { UploadOutlined, LeftOutlined, UserOutlined, MailOutlined, CreditCardOutlined } from '@ant-design/icons'
import { TimezoneArray } from './TimezoneArray'
import './UserSettings.scss'

function UserSettings(){
    const [menuOption, setMenuOption] = useState('user')
    const [timezone, setTimezone] = useState('')
    const [UtcList, setUtcList] = useState([...TimezoneArray])
    const { userInfo, setUserInfo, setInfoLoading, loadName } = useContext(UserContext)

    useEffect(()=>{
        const uid = firebase.auth().currentUser.uid
        const userDoc = firebase.firestore().collection('users').doc(uid)
        userDoc.get()
        .then(userDocSnap =>{
            // console.log('user snapshot date offset: ',userDocSnapshot.data())
            if(userDocSnap.data().timeZoneOffset !== undefined){
                setTimezone = userDocSnap.data().timeZoneOffset
            }
        })
        .catch(error=>{
            window.alert(error.message)
        })
    },[])


    const handleMenuClick = (e) => {
        setMenuOption(e.key)
    }

    const { Option } = Select

    const UtcOptions = TimezoneArray.map(item=>{
        return(
            <Option value={item.value}>{item.text}</Option>
        )
    })


    const onFinish = values => {
        console.log('values->',values)

        //Set name changes
        let fullName = userInfo.firstName + ' ' + userInfo.lastName
        if(fullName !== values.name){
            setInfoLoading(true)
            firebase.auth().currentUser.updateProfile({
                displayName: values.name
            })
            .then(()=>{
                loadName()
                console.log("name updated!")
                
            })
            .catch(error=>{
                window.alert(error.message)
            })
        } 

        //Set timezone changes
        if(timezone !==  values.timezone && values.timezone !== 'Auto'){
            const storeUserSettings = firebase.functions().httpsCallable('storeUserSettings')
            storeUserSettings({
                timeZoneOffset: parseInt(values.timezone)
            })
            .then(response=>{
                console.log('successfully updated!')
            })
            .catch(error=>{
                analytics().logEvent('exception',  {
                    description: error.message
                });
                
                window.alert(error.message)
            })
        }
    }
    
    return(
        <div className='user-settings-page'>
            <Menu className='settings-navigation' theme = 'light' onClick = { handleMenuClick }>
                <Menu.Item key='user' className = 'menu-item' icon = { <UserOutlined /> }>
                    User Information
                </Menu.Item>
                <Menu.Item key='email' className = 'menu-item' icon = { <MailOutlined /> }>
                    Email Settings
                </Menu.Item>
                <Menu.Item key='billing' className = 'menu-item' icon = { <CreditCardOutlined /> }>
                    Billing
                </Menu.Item>
                <Link to = '/dashboard'>
                    <Menu.Item key='back' className = 'menu-item back' icon = { <LeftOutlined /> }>
                        Back
                    </Menu.Item>
                </Link>
            </Menu>
            <div className='main-settings-container'>
                
                <Form
                    className = 'form-wrapper'
                    onFinish ={ onFinish }
                >
                    
                    <div className='settings-row'>
                            <h1>User Settings</h1>
                            <h3>Profile Picture</h3>
                            <div className='settings-container'>
                                <Avatar size = { 128 } icon = { <UserOutlined /> }/>
                                <Button
                                    icon = { <UploadOutlined /> }
                                    type = 'primary'
                                    className = 'upload-btn'
                                >
                                    Update
                                </Button>
                            </div>
                            <h3>Personal information</h3>
                            <div className='settings-container'>
                                <Form.Item
                                    label = 'Display Name'
                                    name='name'
                                    className='settings-input'
                                    initialValue = {`${userInfo.firstName} ${userInfo.lastName}`}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label = 'Timezone'
                                    name='timezone'
                                    className='settings-input'
                                >
                                    <Select placeholder = 'Set timezone manually'>
                                        { UtcOptions }
                                    </Select>
                                </Form.Item>
                            </div>
                            <Form.Item
                                className = 'settings-container submit-btn'
                                name='submit'
                            >
                                <Button htmlType = 'submit' type = 'primary' size = 'large' shape = 'round'>Save Changes</Button>
                            </Form.Item>
                        </div>
                </Form>
            </div>
        </div>
    )
}

export default UserSettings