import React, { useState, useEffect, Profiler } from 'react'
import NoRegistry from '../NoEmails/NoRegistry'
import firebase, { analytics } from 'firebase'
import { Button, DatePicker, Input, Skeleton, Modal, Form, notification } from 'antd'
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined, ClockCircleOutlined, DoubleRightOutlined  } from '@ant-design/icons'
import './ScheduleContainer.scss'


function ScheduleContainer({scheduledEvents, scheduleLoader, setScheduleLoader}){
    const [modalVisible, setModalVisible] = useState(false);    // Visibility of the modal for inputng the scheduled OOO time and date
    const [modalInfo, setModalInfo ] = useState({
        timeStamp : [],
        timeString: []
    })
    const [modalEventTitle, setModalEventTitle] = useState('')
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [notificationContent, setNotificationContent] = useState({     //The content for all notification regarding scheduling
        type: '',
        message: '',
        description: ''
    })

    const { RangePicker } = DatePicker
    const { confirm } = Modal

    useEffect(()=>{
        setScheduleLoader(false)
    },[scheduledEvents])

    //Notification modal for errors
    useEffect(()=>{
        const showNotification = (type) =>{
            notification[type]({
                message: notificationContent.message,
                description: notificationContent.description
            })
        }
    }, [notificationContent])

    //Modal for email deletion confirmation
    function showEventConfirm(value) {
        confirm({
            title: `Do you Want to delete ${value.title}?`,
            icon: <ExclamationCircleOutlined />,
            content: `If you delete this event, your Out of Office will not be activated in this time range`,
            okText: 'Delete',
            okType: 'danger',
            onOk() {
            console.log('OK');
            },
            onCancel() {
            console.log('Cancel');
            },
        });
    }

    const scheduleBtnHandler = () => {
        setModalVisible(true)
    }

    const closeModalHandler = () => {
        setModalVisible(false)
        setModalInfo({
            timeStamp : [],
            timeString: []
        })
    }

    const onTimeChange = (date, dateString)=> {
        setModalInfo({
            timeStamp: [...date],
            timeString: [...dateString]
        })
    }

    const onTitleChange  = (value) => {
        setModalEventTitle(value)
    }

    const createDateTimeWithOffSet = async (date, time) => {
        let naiveDate = new Date(date + " " + time)

        const uid = firebase.auth().currentUser.uid
        const userDoc = firebase.firestore().collection('users').doc(uid)
        const userDocSnapshot = await userDoc.get()
        let splitDate = naiveDate.toString().split('GMT')

        if(userDocSnapshot.data().timeZoneOffset !== undefined){
            const timeZoneOffset = userDocSnapshot.data().timeZoneOffset
            let offset
            if(timeZoneOffset > 0){
                offset = '+' + timeZoneOffset.toString()
            }
            else{
                offset = '-' + (timeZoneOffset*(-1)).toString()
            }
            splitDate[1] = offset
            const realDate = new Date(splitDate.join("GMT"))

            return realDate
        }
        else{
            return naiveDate
        }
    }

    const scheduleSubmitHandler  = async (values) => {
        setScheduleLoader(true)
        var toggleOOO = firebase.functions().httpsCallable('toggleAccountsVacationResponder');
        
        let startTimeString = modalInfo.timeString[0].split(" | ")
        let endTimeString = modalInfo.timeString[1].split(" | ")

        const startTime = await createDateTimeWithOffSet(startTimeString[0], startTimeString[1])
        const endTime = await createDateTimeWithOffSet(endTimeString[0], endTimeString[1])

        const jsonStartTime = startTime.toJSON()
        const jsonEndTime = endTime.toJSON()

        toggleOOO ({
            outOfOffice: true,
            startTime: jsonStartTime,   
            endTime: jsonEndTime,
            title: values.title
        }).then(result => {
            console.log(result)
            setModalVisible(false)
            setScheduleLoader(false)
        }).catch(error => {
            analytics().logEvent('exception',  {
                description: error.message
            });
            console.log(error);
        }) 



        //Llamar a createDateTimeWithOffSet //dashboard.js del viejo
    }

    const scheduledEventContainers = scheduledEvents.map(schedule => {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        }
        return(
            <div key = {schedule.id} className = 'scheduled-event-container' >
                <ClockCircleOutlined className='schedule-icon time-icon'/>
                <h3>{ schedule.title }</h3>
                <div className='schedule-details'>
                    <div className= 'time-container'>
                        <h3>{ schedule.startTime.toDate().toLocaleDateString('en-US', {...options} ) }</h3>
                        <p>{ schedule.startTime.toDate().toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'} ) }</p>
                    </div>
                    <DoubleRightOutlined className='schedule-icon'/>
                    <div className= 'time-container'>
                        <h3>{ schedule.endTime.toDate().toLocaleDateString('en-US', {...options} ) }</h3>
                        <p>{ schedule.endTime.toDate().toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'} ) }</p>
                    </div>
                </div>
                <CloseOutlined 
                    className='schedule-icon exit-icon' 
                    onClick = {()=>{showEventConfirm(schedule)}}
                />
            </div>
        )
    })


    return(
        <div className='schedule-container'>
            <h2>Schedule OOO Event</h2>
            <div className='select-date-container'>
                <Button 
                    size='large' 
                    shape='round' 
                    type = 'primary' 
                    icon = {<PlusOutlined />}
                    onClick = { scheduleBtnHandler }
                >
                New Scheduled Event
                </Button>
                {
                    modalVisible
                        ? (
                            <div className="datepicker-modal">
                                <CloseOutlined className='exit-modal' onClick = {closeModalHandler} />
                                <h3>Select Date & Time</h3>
                                <Form onFinish={scheduleSubmitHandler}>
                                    <Form.Item name='title'>
                                        <Input placeholder='Event title' className='title-input' onChange={ onTitleChange }/>
                                    </Form.Item>
                                    <Form.Item name='dateRange'>
                                        <RangePicker showTime use12Hours format='MM-DD-YY | h:mm A' onChange = { onTimeChange } />
                                    </Form.Item>
                                    <Form.Item>
                                        <div className='btn-container'>
                                            <Button onClick = {closeModalHandler}>Cancel</Button>
                                            <Button type='primary' htmlType='submit' >Create Event</Button>
                                        </div>
                                    </Form.Item>
                                </Form>
                            </div>
                        )
                        : null
                }    
            </div>
            <div className = 'scheduled-event-wrapper'>
                { 
                    scheduleLoader
                    ? (
                        <>
                            <Skeleton.Input active className='loader-skeleton' />
                            <Skeleton.Input active className='loader-skeleton' />
                            <Skeleton.Input active className='loader-skeleton' />
                        </>
                )
                    : scheduledEvents.length > 0
                        ? scheduledEventContainers 
                        : <NoRegistry type='schedule' message = 'Create a planned event to get started' />
                }
            </div>
        </div>
    )
}

export default ScheduleContainer