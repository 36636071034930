import React, {useState} from 'react'
import { Form, Input, Button, Checkbox, Col, Row } from 'antd'
import firebase from 'firebase'
import './SignupForm.scss'

function SignupForm({setErrorContent, setErrorVisible, signUpWithFirebase, signupText, setSignupText}){

    

    const layout = {
        labelCol: { span: 16 },
        wrapperCol: { span: 16 },
    }
    
    const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
    }

    const onFinish = values => {
        console.log(values)
        setSignupText('CREATING ACCOUNT...')
        console.log(values.name)
        signUpWithFirebase({
            email: values.email,
            password: values.password,
            name: values.name
        })
        .then(response=>{
            window.alert('Just created your user account!')
                
        })
        .catch(error=>{
            window.alert("this is the error!")
            setSignupText('CREATE AN ACCOUNT')
            setErrorContent({
              title: 'Error creating account',
              message: 'error.message'
            })
            setErrorVisible(true)
            
        })
    };
    
    const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
    setSignupText('CREATE AN ACCOUNT')
    };

   return(
        <Form
        {...layout}
        layout='vertical'
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        size='large'
        className = "registration-form"
    >
        <Row align='top' justify='space-between' gutter={16}>
        <Col>
            <Form.Item
            label="Email"
            name="email"
            rules={[
            {type: 'email', message: 'The input is not valid E-mail!'},
            { required: true, message: 'Please input your email!' }]}
            className="input"
            >
            <Input className="input-box"/>
            </Form.Item>
        </Col>
        <Col>
            <Form.Item
            label="Full Name"
            name="name"
            rules={[{ required: true, message: 'Please input your name!' }]}
            className="input"
            >
            <Input className="input-box"/>
            </Form.Item>
        </Col>
        </Row>

        <Row align='top' justify='space-between' >
        <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
            className="input"
        >
            <Input.Password className="input-box"/>
        </Form.Item>

        <Form.Item
            label="Confirm Password"
            name="password-confirm"
            dependencies={['password']}
            hasFeedback
            rules={[
                { required: true, message: 'Please confirm your password!' },
                ({ getFieldValue }) => ({
                validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                    }
                    return Promise.reject('The two passwords that you entered do not match!');
                },
    }),
            ]}
            className="input"
        >
            <Input.Password className="input-box"/>
        </Form.Item>
        </Row>
        
        <Row justify='space-between' align='middle'>
        <Col span={24}>
            <Form.Item  name="remember" valuePropName="checked" >
            <Checkbox>I agree to the terms and conditions</Checkbox>
            </Form.Item>
        </Col>
        </Row>

        <Row> 
        <Col span={24} >
            <Form.Item className="button-container" >
            <Button type="primary" htmlType="submit" shape="round" className="submit-btn">
                {signupText}
            </Button>
            </Form.Item>
        </Col>
        </Row>
    </Form>
   )

}

export default SignupForm