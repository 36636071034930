import React from 'react'
import { Switch, Select } from 'antd'
import './SwitchContainer.scss'

function SwitchContainer({ handleSwitchChange, handleTimeChange, oooToggle, switchLoading }){

    const { Option } = Select 

    return(
        <div className="switch-container">
            <h2>Activate OOO Now</h2>
                <div className="toggle-container">
                { switchLoading
                    ? (
                        <Switch
                            loading
                            checkedChildren='Active' 
                            checked = { oooToggle }
                            unCheckedChildren = 'Inactive' 
                            className="toggle" 
                            size="large"
                            onClick = { handleSwitchChange }
                        />
                    )
                    :(
                        <Switch
                            checkedChildren='Active' 
                            checked = { oooToggle }
                            unCheckedChildren = 'Inactive' 
                            className="toggle" 
                            size="large"
                            onClick = { handleSwitchChange }
                        />
                    )
                 }
                    <Select 
                        defaultValue='1' 
                        style={{width: 150}}
                        onChange={handleTimeChange}
                    >
                        <Option value='0.25'>15 Minutes</Option>
                        <Option value='0.5'>30 Minutes</Option>
                        <Option value='1'>1 Hour</Option>
                        <Option value='2'>2 Hours</Option>
                        <Option value='4'>4 Hours</Option>
                        <Option value='8'>8 Hours</Option>
                    </Select>
            </div>
        </div>
    )
}

export default SwitchContainer