import React, { useContext, useState, useEffect } from 'react'
import { NavLink, Link, useHistory } from 'react-router-dom'
import { DownOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, Menu, Skeleton } from 'antd'
import { UserContext } from '../../App'
import * as firebase from 'firebase'
import './NavBar.scss'


function NavBar(){
    const [userLoading, setUserLoading] = useState(false)
    const {userAuth, setUserAuth, userInfo, setUserInfo, infoLoading, setInfoLoading} = useContext(UserContext)
    let history = useHistory()
    const handleMenuClick = (e) => {
        if(e.key == 'user-settings'){
            history.push('/dashboard/user-settings')
        }
        else if(e.key == 'log-out'){
            firebase.auth().signOut().then(function() {
                // Sign-out successful.
            }).catch(function(error) {
            // An error happened.
            });
        }
        else if(e.key == 'ai-suggestions'){
            history.push('dasboard/ai-suggestions')
        }
    }

    useEffect(()=>{
        setInfoLoading(false)
    },[userInfo])

    //Authenticated user dropdown menu
    const { SubMenu } = Menu
    const menu = (
        <Menu>
            <Menu.Item>User Settings</Menu.Item>
            <Menu.Item>Log Out</Menu.Item>
        </Menu>
    )

    return(
        <div className="navbar-component">
            <div className="left-container">
                <Link to = '/'>
                    <h1>OOO</h1>
                </Link>
            </div>
            {
                userAuth 
                    ? (
                        <div className='right-container'>
                            { infoLoading 
                                ? null
                                :(
                                    <>
                                        <Menu mode = 'horizontal' className = 'username' onClick = {handleMenuClick}>
                                            <SubMenu icon={<DownOutlined />}  title={userInfo.firstName} key='username-menu'>
                                            <Menu.Item key='user-settings'>User Settings</Menu.Item> 
                                            <Menu.Item key='ai-suggestions'>AI Suggestions</Menu.Item> 
                                            <Menu.Item key='log-out'>Log Out</Menu.Item> 
                                            </SubMenu>
                                        </Menu>
                                    </>
                                )
                            }
                            <Avatar size = {50} icon = {<UserOutlined />} />
                        </div>
                    )
                    : (
                        <div className="right-container">
                            <NavLink to="/" className="nav-link">HOME</NavLink>
                            <NavLink to="/login" className="nav-btn">LOG IN</NavLink>
                        </div>
                    )
            }
            
        </div>
    )
}

export default NavBar