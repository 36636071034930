import React, { useEffect, useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Form, Input, Button, notification } from 'antd'
import { UserContext } from '../../App'
import firebase, { analytics } from 'firebase'
import './Login.scss'

function Login() {
    const {userAuth, setUserAuth} = useContext(UserContext) 
    const [loginText, setLoginText] = useState('SIGN IN')
    const [errorVisibility, setErrorVisibility] = useState(false)
    const [errorContent, setErrorContent] = useState({
      'title': '',
      'message': ''
    })

    useEffect(()=>{
      if(errorVisibility){
        openNotificationWithIcon('error');
      }
    },[errorVisibility])

    const openNotificationWithIcon = type => {
      notification[type]({
        message: errorContent.title,
        description: errorContent.message,
      });
      setLoginText('SIGN IN')
      setTimeout(()=>{
        setErrorVisibility(false)
      },5000)
    };

    const history = useHistory()

    const loginWithFirebase = ({email, password}) => {
      firebase.auth().signInWithEmailAndPassword(email, password)
      .then(() => {
        analytics().logEvent('login',  {
          method: 'Email and password'
        });
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;

        analytics().logEvent('exception',  {
          description: errorMessage
        });

        setErrorContent({
          'title': 'Error in Sign In',
          'message': errorMessage
        })
        setErrorVisibility(true)
        console.log(`Error in login | Code: ${errorCode} | Message: ${errorMessage}`);
      });
    }

    // useEffect(() => {
    //   firebase.auth().onAuthStateChanged((user) => {
    //     if (user) {
    //       setUserAuth(true);
    //       history.push('/dashboard')
    //     } 
        
    //     else {
    //       setUserAuth(false);
    //     }
    //   });  
    // }, [userAuth])

    const layout = {
        labelCol: {
          span: 16,
        },
        wrapperCol: {
          span: 24,
        },
      };
      const tailLayout = {
        wrapperCol: {
          offset: 0,
          span: 24,
        },
      };
      
      const LoginForm = () => {
        const onFinish = (values) => {
          setLoginText('LOADING...')
          console.log('Success:', values);

          loginWithFirebase({
            email: values.email,
            password: values.password
          })
        };
      
        const onFinishFailed = (errorInfo) => {
          setLoginText('SIGN IN')
          console.log('Failed:', errorInfo);
          setErrorContent({
            'title': 'Error with log in',
            'message': errorInfo
          })
          setErrorVisibility(true)
        };
      
        return (
          <Form
            {...layout}
            layout = 'vertical'
            name="basic"
            size='large'
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="registration-form"
          >
            <Form.Item
              label="Email"
              name="email"
              className="input"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                },
              ]
              
              }
            >
              <Input />
            </Form.Item>
      
            <Form.Item
              label="Password"
              name="password"
              className="input"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
      
            <Form.Item {...tailLayout}>
              <Button type="primary" shape = "round" htmlType="submit" className="submit-btn">
                {loginText}
              </Button>
            </Form.Item>
          </Form>
        );
      };

    return(
        <div className="login-page">
            <div className="auth-modal">
                <h2>Log In</h2>
                <LoginForm />
            </div>
            <p>Don't have an account? <Link to='/signup' className='hyperlink-text'>Sign Up</Link></p>
        </div>
    )
}

export default Login