import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import SignupForm from './SignupForm/SignupForm'
import { notification } from 'antd';
import { UserContext } from '../../App'
import firebase, { analytics } from 'firebase';
import './Signup.scss'

function Signup(){
    const {userAuth, setUserAuth} = useContext(UserContext)
    const [authType, setAuthType] = useState('Sign Up')
    const [errorVisible, setErrorVisible] = useState(false)
    const [errorContent, setErrorContent] = useState({
      title: '',
      message: ''
    })
    const [signupText, setSignupText] = useState('CREATE AN ACCOUNT')

    const history = useHistory()

    const signUpWithFirebase = ({email, password, name}) => {
      firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(response=>{
        analytics().logEvent('sign_up',  {
          method: 'Email and password'
        });

        console.log("authentication response: ", response)
        let user = firebase.auth().currentUser
        console.log('Adding the user name right now ->', name)
        user.updateProfile({
            displayName: name
        })
        .then(response=>{
          console.log('wrapped up-> ', response)
        })
        .catch(error=>{
          console.log('error message: ', error.message)
        })
        setUserAuth(true)
        history.push('/dashboard')
      })
      .catch((error) => {
        analytics().logEvent('exception',  {
          description: error.message
        });

        setErrorContent({
          'title': 'Error creating account',
          'message': error.message
        })
        setErrorVisible(true)
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // console.log(`Error creating user | Code: ${errorCode} | Message: ${errorMessage}`);
      });
    }
    
    useEffect(()=>{
      if(errorVisible){
        openNotificationWithIcon('error')
      }
    }, [errorVisible])

    //Configuration for the error modal
    const openNotificationWithIcon = type => {
      notification[type]({
        message: errorContent.title,
        description: errorContent.message,
      });
      setSignupText('CREATE AN ACCOUNT')
      setTimeout(()=>{
        setErrorVisible(false)
      },5000)
    };
          
    return(
        <div className = "auth-page">
            <div className="auth-modal">
                <h2>{authType}</h2>
                <SignupForm 
                  setErrorContent = { setErrorContent } 
                  setErrorVisible = { setErrorVisible } 
                  signUpWithFirebase = {signUpWithFirebase}
                  signupText = { signupText }
                  setSignupText = { setSignupText }
                />
            </div>
            <p className="change-auth-text">Already have an account?  <Link to="/login" className="hyperlink-text">Log In</Link></p>
        </div>
    )
}

export default Signup