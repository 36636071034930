import React from 'react'
import { MailOutlined, ScheduleOutlined } from '@ant-design/icons'
import './NoRegistry.scss'

function NoRegistry({ type, message }){
    return(
        <div className='no-email-container'>
            {
                type == 'email'
                ? <MailOutlined className='mail-icon'/>
                : <ScheduleOutlined className='mail-icon' />
            }
            
            <h4>{ message }</h4>
        </div>
    )
}

export default NoRegistry