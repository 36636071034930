import React,{useState, useEffect } from 'react'
import { Button, Dropdown, Menu, Modal, Switch, Skeleton, notification } from 'antd'
import { GoogleOutlined, SettingOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import GoogleLogin from 'react-google-login'
import {clientId, scopes} from '../../../../config/credentials'
import NoRegistry from '../NoEmails/NoRegistry'
import firebase, { analytics } from 'firebase'
import './AccountContainer.scss'

function AccountContainer({ loadUserInformation, accountStatus, accountMessages, setAccountMessages, showCustomMessageForm, setMessageAccount, addAccountHandler, noAccounts, setNoAccounts, loading, setLoading, handleAccountSwitch }){
    const [modalContent, setModalContent] = useState({
        type:'',
        message: '',
        description: ''
    })

    useEffect(()=>{
        if(Object.keys(accountStatus).length > 0){
            setNoAccounts(false)
        }
        else{
            setNoAccounts(true)
        }
        setLoading(false)
    },[accountStatus])

    const { confirm } = Modal;
    
    const showModal = (type) => {
        notification[type]({
            message: modalContent.message,
            description: modalContent.description 
        })
        setTimeout(()=>{
            setModalContent({
                type: '',
                message: '',
                description: ''
            }, 5000)
        })
    }

    //UseEffect that will activate the modal every time new parameters are loaded
    useEffect(()=>{
        if(modalContent.type == 'error'){
            showModal('error')
        }
        else if(modalContent.type == 'success'){
            showModal('success')
        }
    }, [modalContent])

    //Modal for email deletion confirmation
    function showConfirm(email) {
        confirm({
            title: `Do you Want to delete ${email}?`,
            icon: <ExclamationCircleOutlined />,
            content: `If you delete this email, all your associated records will be permanently eliminated`,
            okText: 'Delete',
            okType: 'danger',
            onOk() {
                //Make the backend call to eliminate the email account
                setLoading(true)
                eliminateAccount(email)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    
     //Eliminate an account
     const eliminateAccount = (email) => {
        setLoading(true)
        var deleteAccount = firebase.functions().httpsCallable('deleteAccount')
        deleteAccount({
            email: email,
        })
        .then(response=>{
            console.log('Account eliminated successfully:', response)
            setLoading(false)
            loadUserInformation()
        })
        .catch(error=>{
            analytics().logEvent('exception',  {
                description: error.message
            });

            setModalContent({
                type: 'error',
                message: `We couldn't eliminate ${email}`,
                description: 'Please try again shortly'
            })

            setLoading(false)
        })
    }

    //Add the new email in the backend
    const handleNewAccount = response => {
        setLoading(true)
        const code = response.code
        const getAuthCode = firebase.functions().httpsCallable('getAuthCode')
        getAuthCode({
            'authCode': code
        })
        .then(response => {
            console.log(response)
            loadUserInformation()
        })
        .catch(error=>{
            analytics().logEvent('exception',  {
                description: error.message
            });
            
            window.alert(`Error happened: ${error}`)
            setLoading(false)
        })
    }

    //Component for the emails
    const emailContainers = Object.keys(accountStatus).map(email=>{
        
        const status = accountStatus[email]
        //Dropdown menu component for the email containers
        const menu = (
            <Menu >
                <Menu.Item onClick = {() =>{showCustomMessageForm(email)}}>Custom Message</Menu.Item>
                <Menu.Item onClick = {()=>{showConfirm(email)}} >Eliminate Account</Menu.Item>
            </Menu>
        )
        return(
            <div className="email-container" key={ email }>
                <GoogleOutlined className='container-icon'/>
                <p>{ email }</p>
                {
                    status 
                        ? <Switch defaultChecked key={email} onChange = { ()=> { handleAccountSwitch(email) } } />
                        : <Switch key={email} onChange = { ()=> { handleAccountSwitch(email) } } />
                }
                <Dropdown overlay={menu} placement='bottomCenter'>
                    <SettingOutlined className='container-icon option' />
                </Dropdown>
            </div>
        )
    })

    const googleResponse = response =>{
        
    }

    return(
        <div className="accounts-container">
            <h2>Associated Accounts</h2>
            <div className='accounts-wrapper'>
                { 
                    loading
                    ? (
                        <>
                        <Skeleton.Input active className='loader-skeleton' />
                        <Skeleton.Input active className='loader-skeleton' />
                        <Skeleton.Input active className='loader-skeleton' />
                        </>
                    )
                    :
                        noAccounts
                        ? <NoRegistry type = 'email' message = 'Add an account to get started'/>
                        : emailContainers
                    
                }
            </div>

            <GoogleLogin
                clientId = {clientId}
                scope = {scopes}
                responseType='code'
                prompt = 'consent'
                onSuccess = { handleNewAccount }
                onFailure = { googleResponse }
                render = {renderProps => (
                    <Button 
                        icon={ <GoogleOutlined /> }
                        size='large'
                        type='primary'
                        shape='round'
                        className='account-btn'
                        onClick = { renderProps.onClick }
                        disabled = { renderProps.disabled} 
                    >
                        Add Gmail Account
                    </Button>
                )}
            />
            
            
        </div>
    )
}

export default AccountContainer