import React, {useState} from 'react'
import { Drawer, Button, Modal } from 'antd'
import { CalendarOutlined, LaptopOutlined, ExclamationCircleOutlined, DoubleRightOutlined, CloseOutlined, UnorderedListOutlined } from '@ant-design/icons'
import './GeneralQueue.scss'


function GeneralQueue({generalQueue}){
    const [queueVisible, setQueueVisible] = useState(false)

    const { confirm } = Modal

    const onClose = () => {
        setQueueVisible(false)
    }

    const GeneralQueueContainers = generalQueue.map(schedule => {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        }
        console.log('schedule data structure->',schedule)
        return(
            <div key = {schedule.id} className = 'general-queue-event-container' >
            {
                schedule.uiSchedule
                ?  <LaptopOutlined  className='schedule-icon time-icon'/>
                : <CalendarOutlined className='schedule-icon time-icon'/>
            }
               
                <h3>{ schedule.title }</h3>
                <div className='schedule-details'>
                    <div className= 'time-container'>
                        <h3>{ schedule.startTime.toDate().toLocaleDateString('en-US', {...options} ) }</h3>
                        <p>{ schedule.startTime.toDate().toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'} ) }</p>
                    </div>
                    <DoubleRightOutlined className='schedule-icon'/>
                    <div className= 'time-container'>
                        <h3>{ schedule.endTime.toDate().toLocaleDateString('en-US', {...options} ) }</h3>
                        <p>{ schedule.endTime.toDate().toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'} ) }</p>
                    </div>
                </div>
                <CloseOutlined 
                    className='schedule-icon exit-icon' 
                    onClick = {()=>{showEventConfirm(schedule)}}
                />
            </div>
        )
    })

    function showEventConfirm(value) {
        confirm({
            title: `Do you Want to delete ${value.title}?`,
            icon: <ExclamationCircleOutlined />,
            content: `If you delete this event, your Out of Office will not be activated in this time range`,
            okText: 'Delete',
            okType: 'danger',
            onOk() {
            console.log('OK');
            },
            onCancel() {
            console.log('Cancel');
            },
        });
    }


    return(
        <div className='general-queue-component'>
            <Button
                className = 'queue-btn'
                onClick = { ()=>{setQueueVisible(true)} }
                size = 'large'
                icon = {<UnorderedListOutlined />}
            ></Button>
            {
                queueVisible
                    ? (
                        <Drawer
                            title='Your OOO list'
                            placement = 'right'
                            visible = {queueVisible}
                            onClose = { onClose }
                            width={720}
                            className = 'queue-drawer'
                        >
                            {GeneralQueueContainers}
                        </Drawer>
                    )
                    : null
            }
        </div>
    )
}

export default GeneralQueue