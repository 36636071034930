import React from 'react'
import { Button } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import './Landing.scss'
import landingImage from '../../assets/landing-image.svg'
import firebase, { analytics } from 'firebase'

function Landing(){
    // Confirm the link is a sign-in with email link.
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        var email = window.localStorage.getItem('emailForSignIn');

        if (!email) {
            // User opened the link on a different device. To prevent session fixation
            // attacks, ask the user to provide the associated email again. For example:
            email = window.prompt('Please provide your email for confirmation');
        }
        
        // The client SDK will parse the code from the link for you.
        firebase.auth().signInWithEmailLink(email, window.location.href)
        .then(function(result) {
            analytics().logEvent('sign_up',  {
                method: 'Email link'
            });

            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn');
            // Print user data
            console.log(result.user);
        })
        .catch(function(error) {
            analytics().logEvent('exception',  {
                description: error.message
            });

            console.log(error);
        });
    }
    
    return(
        <div className = "landing-page">
            <div className='left-container'>
                <h2>Regain Ownership of your Time</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit nec, faucibus sit amet. Ultrices placerat semper odio at tincidunt eu blandit. Nullam neque, suspendisse duis aliquam et. Donec risus, mauris eu ac. Enim neque egestas etiam consequat egestas. Sed faucibus quam sit metus.</p>
                <Link to = '/signup' className='center-align'>
                <Button 
                    icon = { <RightOutlined /> }
                    size = 'large'
                    shape = 'round'
                    className='cta-btn'
                    
                >Get Started
                </Button>
                </Link>
            </div>
            <div className='right-container'>
                <img src={ landingImage } />
            </div>
        </div>
    )
}

export default Landing