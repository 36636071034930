import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyDLcJrUOubXBgT1i1ks_8iB1ZzCSUKqHHc",
  authDomain: "i-am-very-ooo-demo-19774.firebaseapp.com",
  databaseURL: "https://i-am-very-ooo-demo-19774.firebaseio.com",
  projectId: "i-am-very-ooo-demo-19774",
  storageBucket: "i-am-very-ooo-demo-19774.appspot.com",
  messagingSenderId: "873725470031",
  appId: "1:873725470031:web:4de43a547e133c9f77513a",
  measurementId: "G-WLEWGW2YXZ"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
