export const TimezoneArray = [
    { text:'Automatic', value: 'Auto'},
    { text:'UTC -12:00', value: '-12'},
    { text:'UTC -11:00', value: '-11'},
    { text:'UTC -10:00', value: '-10'},
    { text:'UTC -09:30', value: '-930'},
    { text:'UTC -09:00', value: '-9'},
    { text:'UTC -08:00', value: '-8'},
    { text:'UTC -07:00', value: '-7'},
    { text:'UTC -06:00', value: '-6'},
    { text:'UTC -05:00', value: '-5'},
    { text:'UTC -04:30', value: '-430'},
    { text:'UTC -04:00', value: '-4'},
    { text:'UTC -03:30', value: '-330'},
    { text:'UTC -03:00', value: '-3'},
    { text:'UTC -02:00', value: '-2'},
    { text:'UTC -01:00', value: '-1'},
    { text:'UTC +00:00', value: '0'},
    { text:'UTC +01:00', value: '1'},
    { text:'UTC +02:00', value: '2'},
    { text:'UTC +03:00', value: '3'},
    { text:'UTC +03:30', value: '330'},
    { text:'UTC +04:00', value: '4'},
    { text:'UTC +04:30', value: '430'},
    { text:'UTC +05:00', value: '5'},
    { text:'UTC +05:30', value: '530'},
    { text:'UTC +05:45', value: '545'},
    { text:'UTC +06:00', value: '6'},
    { text:'UTC +06:30', value: '630'},
    { text:'UTC +07:00', value: '7'},
    { text:'UTC +08:00', value: '8'},
    { text:'UTC +08:45', value: '845'},
    { text:'UTC +09:00', value: '9'},
    { text:'UTC +09:30', value: '930'},
    { text:'UTC +10:00', value: '10'},
    { text:'UTC +10:30', value: '1030'},
    { text:'UTC +11:00', value: '11'},
    { text:'UTC +12:00', value: '12'},
    { text:'UTC +12:45', value: '1245'},
    { text:'UTC +13:00', value: '13'},
    { text:'UTC +14:00', value: '14'},
]