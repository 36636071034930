import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Result, Spin } from 'antd';
import firebase, { analytics } from 'firebase';
import './BetaRegistration.scss';

function BetaRegistration() {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);       

    const RegistrationForm = () => {
        const layout = {
            labelCol: {
              span: 16,
            },
            wrapperCol: {
              span: 24,
            },
        };
    
        const tailLayout = {
          wrapperCol: {
            offset: 0,
            span: 24,
          },
        };
    
        const onFinish = (values) => {
            setLoading(true);

            const email = values.email;
            const saveApplicant = firebase.functions().httpsCallable('saveApplicant');

            saveApplicant ({
                email: email 
            }).then(() => {
                setSubmitted(true);
                setLoading(false);
            }).catch(error => {
                analytics().logEvent('exception',  {
                    description: error.message
                });

                console.log(error);
            })
        };
        
        const onFinishFailed = (errorInfo) => {
            console.log('Failed:', errorInfo);
        };

        return (
            <Form
                {...layout}
                layout = 'vertical'
                name="basic"
                size='large'
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="registration-form"
                >
                <Form.Item
                    label="Email"
                    name="email"
                    className="input"
                    rules={[
                    {
                        required: true,
                        message: 'Please input your email!',
                    },
                    ]
                    
                    }
                >
                    <Input />
                </Form.Item>
            
                <Form.Item {...tailLayout}>
                    <Button type="primary" shape = "round" htmlType="submit" className="submit-btn">
                    Register
                    </Button>
                </Form.Item>
            </Form>
        );
    }
    
    return (
        <div className="registration-page">
            <div className="auth-modal">
                
                {
                    !submitted ? 
                        <>
                        <h2>Sign Up</h2>
                        <RegistrationForm /> 
                        <p className="change-auth-text">Already have an account?  <Link to="/login" className="hyperlink-text">Log In</Link></p>
                        </>
    
                    :

                    (
                        loading ? 
                            <Spin/>
                        :
                            <Result
                            status="success"
                            title="Your registration was completed successfully but you need to wait until the admin activate your account to start enjoying the OOO application!"
                            subTitle="You will soon receive an email with the information to log in."
                            />
                    )
                }
            </div>
        </div>
    );
}

export default BetaRegistration;
