import React, { useEffect } from 'react'
import { Drawer, Form, Input, Button } from 'antd'
import './MessageChange.scss'

function MessageChange({messageDrawer, messageAccount, setMessageAccount, messageEditSubmit, messageEditCancel, accountMessages }){

    useEffect(()=>{
        
        console.log(messageAccount + ": " + accountMessages[messageAccount])
        
    },)
    
    return(
        <Drawer
            title={`Edit your autoreponder message for ${messageAccount}`}
            placement='left'
            visible={ messageDrawer }
            width = {600}
            onClose = {messageEditCancel}
            className = 'custom-message-drawer'
        >
            <Form
                layout = 'vertical'
                onFinish = {messageEditSubmit}
                // initialValues = {{
                //     ["autoReplyMessage"]: accountMessages[messageAccount],
                // }}
                // fields = {
                //     [
                //         {
                //             name: "autoReplyMessage",        
                //             value: `${accountMessages[messageAccount]}`
                //         }
                //     ]
                // }
            >
                <Form.Item
                    name = 'autoreplyMessage'
                    label = 'Edit your autoreply message'
                    className = 'message-input'
                    // value = { accountMessages[messageAccount] }
                    initialValue = { accountMessages[messageAccount] }
                >
                    <Input.TextArea 
                        rows={15}
                        placeholder = 'Type your message here'
                        
                    />
                </Form.Item>
                <Button onClick={messageEditCancel} 
                style={{ marginRight: 8 }}>
                    Cancel
              </Button>
              <Button 
                type="primary"
                htmlType = "submit"
                >
                    Submit
              </Button>
            </Form>
        </Drawer>
    )
}

export default MessageChange